import { Injectable } from '@angular/core';
import { signIn, signOut, getCurrentUser, fetchAuthSession, AuthUser, updateUserAttribute, SignInOutput, confirmSignIn } from 'aws-amplify/auth';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { GenericResponse } from 'src/app/shared/models/global';
// import { GtmService } from 'src/app/shared/services/gtm/gtm.service';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { APOLLO_USER_GROUPS_KEYS, UserTypes, userPermissions } from '../../models/user';
import { GtmService } from '../gtm/gtm.service';

export interface AuthState {
  isLoggedIn: boolean;
  id?: string;
  username?: string;
  groups?: APOLLO_USER_GROUPS_KEYS[];
  sevenDigitalUserId?: string;
  facilityId?: string;
  deviceId?: string;
  userType?: UserTypes;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  readonly initialAuthState: AuthState = {
    isLoggedIn: false,
  };

  private readonly _authState = new BehaviorSubject<AuthState>({ ...this.initialAuthState });
  readonly authState$ = this._authState.asObservable();

  set authState(authState: AuthState) {
    this._authState.next(authState);
  }

  get authState() {
    return this._authState.value;
  }

  constructor(
    private appState: AppStateService,
    // private timeoutService: TimeoutService,
    private gtmService: GtmService,
    private apiService: ApiService,
  ) { }


  customChallengeLogin(input: { challenge: string, username: string, removeChallenge?: boolean }): Observable<{ status: string }> {
    const { challenge, username, removeChallenge } = input;

    return this.programmaticLogout({ clearState: true }).pipe(
      switchMap(() => this.signIn({ username })
        .pipe(
          switchMap(res => {
            if (res === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
              return from(confirmSignIn({ challengeResponse: challenge }))
                .pipe(
                  map(res => res.nextStep.signInStep)
                );
            } else {
              return of(res);
            }
          }),
          switchMap(status => {
            if (status === 'DONE') {
              return this.getAuthSession()
            }
            return undefined;
          }),
          map(authSession => {
            if (authSession.tokens) {
              const { idToken, accessToken } =  authSession.tokens;
              const accessTokenPayload = accessToken.payload;
              const idTokenPayload = idToken.payload;
              this.setAuthState(idTokenPayload, accessTokenPayload);

              if (removeChallenge) {
                // remove challenge (self closing Observable)
                this.updateUserAttribute({
                  attributeKey: 'custom:authChallenge',
                  value: ''
                }).subscribe();
              }
              return { status: 'SUCCESS' }
            }
            return { status: 'FAIL' }
          }),
          catchError(error => {
            console.log(error);
            return of({
              status: 'ERROR'
            });
          })
        )))
  }


  verifyToken(input: { token: string }) {
    this.appState.initState();
    const statement = `
      query verifyAccessToken($input: VerifyAccessTokenInput!) {
        verifyAccessToken(input: $input) {
          status
          message
          payload
        }
      }
    `;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'verifyAccessToken', iam: true })
  }

  registerFamilyMember(input: { firstName: string, lastName: string, email: string, token: string }) {
    const statement = `
    mutation addFamilyMember($input: AddFamilyMemberInput!) {
      addFamilyMember(input: $input) {
        status
        message
      }
    }
  `;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'addFamilyMember', iam: true })
  }

  programmaticLogout(params?: { clearState: boolean }) {
    const { clearState = true } = params || {};
    return this.signOut().pipe(
      tap(val => {
        if (clearState) {
          this.appState.clearState();
        }
      })
    );
  }

  logout() {
    return this.signOut().pipe(
      tap(() => {
        this.appState.clearState();
        this.authState = { ...this.initialAuthState };
        // this.timeoutService.stopTimer();
        this.gtmService.pushEvent({
          event: 'logout'
        });
      }),
      map(val => true)
    )
  }

  /**
   * This logout will remove the custom:authChallenge first before calling the regular logout
   */
  logoutFamily(removeToken = false) {
    return this.getCurrentUser()
      .pipe(
        switchMap(res => {
          const { user } = res;
          if (user && removeToken) {
            return this.updateUserAttribute({ attributeKey: 'custom:authChallenge', value: '' });
          }
          return of(res);
        }),
        switchMap(() => this.signOut()),
      )
  }


  /**
   * This function checks if the cognito session is still active and pushes a next authState object.
   * Do NOT call this function outside the `auth.guard`, it can cause a authState conflict.
   */
  isLoggedIn(): Observable<AuthState | null> {
    return this.getAuthSession()
      .pipe(
        map(authSession => {
          if (authSession.tokens) {
            const { idToken, accessToken } = authSession.tokens;
            const idTokenPayload = idToken.payload;
            const accessTokenPayload = accessToken.payload;
            this.setAuthState(idTokenPayload, accessTokenPayload);
            return this.authState;
          } else {
            return { ...this.initialAuthState };
          }
        })
      );
  }

  hasGroupAccess(groups: any): boolean {
    if (this.authState.groups && Array.isArray(groups)) {
      const match = this.authState.groups.filter(group => {
        return groups.indexOf(group) !== -1;
      });
      if (!match.length) {
        return false
      }
      return true;
    }
    return false;
  }



  private setAuthState(payload: any, accessTokenPayload: any): void {
    const groups = payload['cognito:groups'];
    const userType = groups.includes(UserTypes.FAMILY) ?
      UserTypes.FAMILY : undefined;

    const authState: AuthState = {
      isLoggedIn: true,
      username: payload['cognito:username'],
      id: payload.sub,
      groups,
      deviceId: accessTokenPayload.device_key,
      userType,
      facilityId: payload.facilityId,
    };
    if (payload.residentId) {
      this.appState.setState('currentResidentId', payload.residentId);
    } else {
      console.error('Resident ID missing in IdToken');
    }
    if (payload.facilityId) {
      authState.facilityId = payload.facilityId
      this.appState.setState('currentFacilityId', payload.facilityId);
    } else {
      console.error('Facility ID missing in IdToken');
    }


    this.appState.setState('currentUser', {
      id: authState.id!,
      username: authState.username!,
      userType,
      permissions: groups as userPermissions[],
    });
    // push the authstate because someone might have refreshed the browser and then the authstate
    // would be in its initial state
    this.authState = authState;
  }


  // NEW FUNCTIONS


  private signIn({ username, password }: loginInput): Observable<string> {
    let signInRes: Observable<SignInOutput>;
    if (password) {
      signInRes = from(signIn({ username, password }))
    } else {
      signInRes = from(signIn({
        username, options: {
          authFlowType: 'CUSTOM_WITHOUT_SRP'
        }
      }))
    }
    return signInRes
      .pipe(
        map(res => res.nextStep.signInStep),
        catchError((error: any) => {
          let response = 'UNKNOWN_ERROR';
          if (error.message === 'There is already a signed in user.') {
            return of('ALREADY_LOGGED_IN');
          }
          return of(response);
        })
      )
  }

  /**
 * @description Get the current Cognito AuthSession. Returns object with empty values if not authenticated
 * @returns Observable AuthSession
 */
  private getAuthSession() {
    return from(fetchAuthSession());
  }

  private signOut() {
    return from(signOut());
  }

  private updateUserAttribute({ attributeKey, value }: updateUserAttributeInput): Observable<boolean> {
    return from(updateUserAttribute({
      userAttribute: {
        attributeKey,
        value,
      }
    })
    ).pipe(
      map(res => {
        if (res.nextStep.updateAttributeStep === 'DONE') {
          return true;
        } else {
          // NEXT STEP HAS TO BE HANDLED
          return false;
        }
      })
    )
  }

  /**
   * @description gets username and userId. No network call (from session storage?)
   */
  private getCurrentUser(): Observable<{ user?: AuthUser, error?: string }> {
    return from(getCurrentUser())
      .pipe(
        catchError((error: any) => {
          let response = 'UNKNOWN_ERROR';
          if (error.message === 'User needs to be authenticated to call this API.') {
            response = 'NOT_AUTHENTICATED';
          }
          return of(response);
        }),
        map((res: AuthUser | string) => {
          if (typeof (res) === 'string') {
            return {
              error: res
            }
          } else {
            return {
              user: res
            }
          }
        })
      )
  }
}


type loginInput = {
  username: string;
  password?: string;
}

type updateUserAttributeInput = {
  attributeKey: string;
  value: string;
}

