import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Dictionary } from 'src/app/shared/models/global';
import { getRecursiveParams } from 'src/app/shared/utils/getRecursiveParams';
import { AppStateService } from '../app-state/app-state.service';
import { environment } from 'src/environments/environment';
import { Facility } from '../facility/facility.service';
import { ApCognitoUser } from '../../models/user';
import { Resident } from '../resident/resident.service';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  enableGtm = environment.local ? !['localhost', environment.local.domain].includes(window.location.hostname) : true;
  private gtm: GoogleTagManagerService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appState: AppStateService,
  ) {
    /**
    * Log page transitions to GTM
    */
    if (this.enableGtm) {
      this.gtm = inject(GoogleTagManagerService);
      this.router.events.forEach(item => {

        if (item instanceof NavigationEnd) {
          const payload = {
            ...this.getPayload({
              event: 'pageLoad',
              url: item.url,
            })
          }

          this.gtm.pushTag(payload);
        }
      });
    }
  }

  pushEvent(data: {
    event: string;
    [key: string]: string;
  }) {
    if (!this.enableGtm) { return };
    const payload = {
      ...this.getPayload({
        ...data
      })
    }
    this.gtm.pushTag(payload);

  }

  /**
   * Construct a payload for GTM and enrich it with extra data passed as params
   */
  private getPayload(data: {
    event: string;
    [key: string]: string;
  }): Dictionary<any> {
    const facility = this.appState.get<Facility>('currentFacility');
    const resident = this.appState.get<Resident>('currentResident');
    const currentUser = this.appState.get<ApCognitoUser>('currentUser')
    return {
      ...data,
      resident_id: resident ? resident.id : '',
      user_type: currentUser ? currentUser.userType : '',
      legacy_facility_id: facility ? facility.legacyFacilityId : '',
      facility_id: facility ? facility.id : '',
      facility_name: facility ? facility.name : '',
      apollo: true
    };

  }
}
